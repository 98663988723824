:root {
	/* 34px */
	--font-size-h1: 2.125rem;

	/* 26px */
	--font-size-h2: 1.625rem;

	/* 20px */
	--font-size-h3: 1.250rem;

	/* 18px */
	--font-size-h4: 1.125rem;

	/*  20px */
	--font-size-ingress: 1.25rem;

	/* 16px */
	--font-size-p: 1rem;

	/* 14px */
	--font-size-smaller: 0.875rem;

	/* 14px */
	--font-size-small: 0.875rem;

	@media (--desktop) {
		/* 48px */
		--font-size-h1: 3rem;

		/* 34px */
		--font-size-h2: 2.125rem;

		/* 26px */
		--font-size-h3: 1.625rem;

		/* 20px */
		--font-size-h4: 1.250rem;

		/* 24px */
		--font-size-ingress: 1.5rem;

		/* 18px */
		--font-size-p: 1.125rem;

		/* 16px */
		--font-size-smaller: 1rem;
	}
}


body {
	-webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: bold;
	line-height: 120%;

	&.section-heading {
		text-align: center;
		position: relative;
		overflow: hidden;
		margin-bottom: 32px;

		@media (--from-xlarge) {
			margin-bottom: 56px;
		}

		@media (--from-medium) {
			white-space: nowrap;
		}
	}
}

h1 {
	font-size: 26px;
	margin-bottom: 16px;
	margin-top: 16px;
	text-align: center;

	@media (--from-large) {
		font-size: 32px;
	}
}

h2 {
	font-size: 24px;
	margin-bottom: 10px;

	@media (--from-large) {
		font-size: 28px;
	}
}

h3 {
	font-size: 20px;

	@media (--from-large) {
		font-size: 22px;
	}
}

h4 {
	font-size: var(--font-size-p);
}

h5 {
	font-size: var(--font-size-smaller);
}

p {
	color: var(--color-oxford);
	margin: 0 0 24px;
	font-size: 14px;
	line-height: 140%;

	@media (--from-large) {
		font-size: 16px;
		margin: 0 0 32px;
	}
}

.text-emphasized {
	font-weight: bold;
}

.text {
	font-size: 14px;
	line-height: 19px;
}