/**
 * Svg-basert løsning for visning av ikoner
 */

.icon {
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center;
    flex: 0 0 auto;
    background-size: var(--icon-size, 1em) var(--icon-size, 1em);
    width: var(--icon-size, 1em);
    height: var(--icon-size, 1em);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: var(--mask-image, none);

    @media print {
        background-size: var(--icon-size, 1em) var(--icon-size, 1em) !important;
    }

    span& {
        display: inline-block;
    }

    &.white {
        background-color: var(--color-white);
    }
}

/*************************************************************/

.icon-my-profile-header {
    background-color: currentColor;
    --mask-image: url(../images/header/my-profile-icon.svg);
}

.icon-cart {
    background-color: var(--color-nevada);
    --mask-image: url(../images/svg/icon-cart.svg);
}

/**********************************/

.icon-chevron-up {
    background-color: var(--color-nevada);

    --mask-image: url(../images/svg/icon-chevron-up.svg);
}

.icon-chevron-down {
    background-color: var(--color-nevada);

    --mask-image: url(../images/svg/icon-chevron-down.svg);

    /* chevron down is often used in toggles */
    [aria-expanded]>& {
        transition: transform .25s ease-in-out;
    }

    [aria-expanded=true]>& {
        transform: rotate(-180deg);
    }
}

.icon-chevron-right {
    font-size: 10px;
    background-color: var(--color-nevada);

    --mask-image: url(../images/svg/icon-chevron-right.svg);
}

.icon-chevron-left {
    font-size: 10px;
    background-color: var(--color-nevada);

    --mask-image: url(../images/svg/icon-chevron-left.svg);
}

/**********************************/

.icon-add {
    background-image: url(../images/svg/icon-add.svg);
}

/**********************************/

.icon-favorite {
    background-color: currentColor;
    --mask-image: url(../images/header/favorite-icon.svg);
}

.icon-order-list {
    background-image: url(../images/svg/profile/icon-order-list.svg);
}

/**********************************/

.icon-my-profile {
    background-image: url(../images/svg/profile/icon-my-profile.svg);
}

.icon-location-pin {
    background-image: url('../images/svg/icon-location-pin.svg');
}

.icon-payment-info {
    background-image: url(../images/credit-card-logo.svg);
}

.icon-my-store {
    background-image: url(../images/svg/profile/icon-my-store.svg);
}

.icon-my-notifications {
    background-image: url(../images/svg/profile/icon-notification.svg);
}

.icon-thumbsup {
    background-image: url(../images/svg/icon-thumbsup.svg);
}

.icon-privacy {
    background-image: url(../images/svg/profile/icon-privacy.svg);
}

/**********************************/

.icon-review {
    background-image: url(../images/svg/icon-review.svg);

    &.icon-review-list {
        background-image: url(../images/svg/icon-review-list.svg);
    }

    &.icon-review-favorite {
        background-image: url(../images/svg/icon-review-favorite.svg);
    }

    &.icon-review-list.icon-review-favorite {
        background-image: url(../images/svg/icon-review-list-favorite.svg);
    }
}

/**********************************/

.icon-alert-error {
    background-image: url(../images/svg/icon-alert-error.svg);
}

.icon-alert-info {
    background-color: var(--color-swamp);
    --mask-image: url(../images/svg/icon-alert-info.svg);

    &.deep-sea-green {
        background-color: var(--color-deep-sea-green);
    }
}

/**********************************/

.icon-close-button {
    background-image: url('../images/svg/icon-close-button.svg');
}

.icon-close {
    background-image: url('../images/svg/icon-close.svg');
}

.icon-xmark {
    background-color: currentColor;
    --mask-image: url(../images/svg/icon-xmark.svg);
}

/**********************************/

.icon-external-link {
    --mask-image: url(../images/svg/icon-external-link.svg);
    background-color: currentColor;
}

/**********************************/

.icon-stock {
    background-color: var(--color-nevada);

    &.icon-store {
        --mask-image: url(../images/svg/stock/icon-store.svg);
    }

    &.icon-delivery {
        --mask-image: url(../images/svg/stock/icon-delivery.svg);
    }

    &.unavailable {
        background-color: var(--color-iron);
    }
}

/**********************************/

.icon-email {
    background-color: var(--icon-color, currentColor);
    --mask-image: url('../images/svg/icon-email.svg');
}

.icon-phone {
    background-color: var(--icon-color, currentColor);
    --mask-image: url('../images/svg/icon-phone.svg');
}

.icon-chat {
    background-color: var(--icon-color, currentColor);
    --mask-image: url('../images/svg/icon-chat.svg');
}

/**********************************/

.icon-delete {
    font-size: 17px;
    background-color: var(--color-nevada);
    --mask-image: url(../images/svg/icon-delete.svg);

    &.black {
        background-color: var(--color-swamp);
    }
}

.icon-edit {
    background-image: url(../images/svg/icon-pencil.svg);
}

/**********************************/

.icon-comment {
    background-image: url(../images/svg/icon-comment.svg);
}

.icon-my-list {
    background-image: url(../images/svg/icon-list-selected.svg);
}

/**********************************/

.icon-increase {
    font-size: 12px;
    background-color: currentColor;
    --mask-image: url(../images/svg/icon-increase.svg);
}

.icon-decrease {
    font-size: 12px;
    background-color: currentColor;
    --mask-image: url(../images/svg/icon-decrease.svg);
}

/**********************************/

.icon-global-search {
    font-size: 24px;
    background-color: currentColor;
    --mask-image: url(../images/svg/icon-global-search.svg);
}

.icon-download {
    font-size: 24px;
    background-image: url(../images/svg/icon-download.svg);
}

.icon-share {
    background-image: url(../images/svg/icon-share.svg);
}

/**********************************/

.sticker-icon {
    font-size: 24px;

    &.icon-biodynamic {
        background-image: url(../images/svg/binders/icon-biodynamic.svg);
    }

    &.icon-eco {
        background-image: url(../images/svg/binders/icon-eco.svg);
    }

    &.icon-environmentmarking {
        background-image: url(../images/svg/binders/icon-environmentmarking.svg);
    }

    &.icon-environmentpackaging {
        background-image: url(../images/svg/binders/icon-environmentpackaging.svg);
    }

    &.icon-fairtrade {
        background-image: url(../images/svg/binders/icon-fairtrade.svg);
    }

    &.icon-gluten {
        background-image: url(../images/svg/binders/icon-gluten.svg);
    }

    &.icon-kosher {
        background-image: url(../images/svg/binders/icon-kosher.svg);
    }

    &.icon-naturalwine {
        background-image: url(../images/svg/binders/icon-naturalwine.svg);
    }

    &.icon-orangewine {
        background-image: url(../images/svg/binders/icon-orangewine.svg);
    }

    &.icon-vegan {
        background-image: url(../images/svg/binders/icon-vegan.svg);
    }

    &.icon-sweetwine {
        background-image: url(../images/svg/binders/icon-sweetwine.svg);
    }
}

/**********************************/

.icon-grape {
    font-size: 26px;
    background-color: currentColor;
    --mask-image: url(../images/svg/icon-grape.svg);
}

/**********************************/

.product-icon {
    font-size: 26px;

    &.mainCategory {
        &.rødvin {
            background-image: url(../images/svg/facets/mainCategory-rødvin.svg);
        }

        &.hvitvin {
            background-image: url(../images/svg/facets/mainCategory-hvitvin.svg);
        }

        &.brennevin {
            background-image: url(../images/svg/facets/mainCategory-brennevin.svg);
        }

        &.øl {
            background-image: url(../images/svg/facets/mainCategory-øl.svg);
        }

        &.musserende_vin {
            background-image: url(../images/svg/facets/mainCategory-musserende_vin.svg);
        }

        &.rosévin {
            background-image: url(../images/svg/facets/mainCategory-rosevin.svg);
        }

        &.sterkvin {
            background-image: url(../images/svg/facets/mainCategory-sterkvin.svg);
        }

        &.perlende_vin {
            background-image: url(../images/svg/facets/mainCategory-perlende_vin.svg);
        }

        &.sider {
            background-image: url(../images/svg/facets/mainCategory-sider.svg);
        }

        &.aromatisert_vin {
            background-image: url(../images/svg/facets/mainCategory-aromatisert_vin.svg);
        }

        &.alkoholfritt {
            background-image: url(../images/svg/facets/mainCategory-alkoholfritt.svg);
        }

        &.sake {
            background-image: url(../images/svg/facets/mainCategory-sake.svg);
        }

        &.gaveartikler_og_tilbehør {
            background-image: url(../images/svg/facets/mainCategory-gaveartikler_og_tilbehør.svg);
        }

        &.fruktvin {
            background-image: url(../images/svg/facets/mainCategory-fruktvin.svg);
        }

        &.mjød {
            background-image: url(../images/svg/facets/mainCategory-mjød.svg);
        }
    }

    &.styleCategory {
        print-color-adjust: exact;

        &.stil001 {
            background-image: url(../images/style/stil001.png);
        }

        &.stil002 {
            background-image: url(../images/style/stil002.png);
        }

        &.stil003 {
            background-image: url(../images/style/stil003.png);
        }

        &.stil004 {
            background-image: url(../images/style/stil004.png);
        }

        &.stil005 {
            background-image: url(../images/style/stil005.png);
        }

        &.stil006 {
            background-image: url(../images/style/stil006.png);
        }

        &.stil007 {
            background-image: url(../images/style/stil007.png);
        }

        &.stil008 {
            background-image: url(../images/style/stil008.png);
        }

        &.stil009 {
            background-image: url(../images/style/stil009.png);
        }

        &.stil010 {
            background-image: url(../images/style/stil010.png);
        }
    }

    &.isGoodfor {
        &.A {
            background-image: url(../images/svg/facets/isGoodFor-A.svg);
        }

        &.B {
            background-image: url(../images/svg/facets/isGoodFor-B.svg);
        }

        &.C {
            background-image: url(../images/svg/facets/isGoodFor-C.svg);
        }

        &.D {
            background-image: url(../images/svg/facets/isGoodFor-D.svg);
        }

        &.E {
            background-image: url(../images/svg/facets/isGoodFor-E.svg);
        }

        &.F {
            background-image: url(../images/svg/facets/isGoodFor-F.svg);
        }

        &.G {
            background-image: url(../images/svg/facets/isGoodFor-G.svg);
        }

        &.H {
            background-image: url(../images/svg/facets/isGoodFor-H.svg);
        }

        &.L {
            background-image: url(../images/svg/facets/isGoodFor-L.svg);
        }

        &.N {
            background-image: url(../images/svg/facets/isGoodFor-N.svg);
        }

        &.Q {
            background-image: url(../images/svg/facets/isGoodFor-Q.svg);
        }

        &.R {
            background-image: url(../images/svg/facets/isGoodFor-R.svg);
        }
    }

    &.Lagringsgrad {

        &.drikkenaa,
        &.X {
            background-image: url(../images/svg/facets/Lagringsgrad-drikkenaa.svg);
        }

        &.drikkelagre,
        &.Y {
            background-image: url(../images/svg/facets/Lagringsgrad-drikkelagre.svg);
        }

        &.lagre,
        &.Z {
            background-image: url(../images/svg/facets/Lagringsgrad-lagre.svg);
        }
    }

    &.Emballasjetype {
        &.Glass {
            background-image: url(../images/svg/facets/Emballasjetype-Glass.svg);
        }

        &.Bag-in-box {
            background-image: url(../images/svg/facets/Emballasjetype-Bag-in-box.svg);
        }

        &.Emballasje-med-pant {
            background-image: url(../images/svg/facets/EmballasjeType-Emballasje-med-pant.svg);
        }

        &.Metall {
            background-image: url(../images/svg/facets/Emballasjetype-Metall.svg);
        }

        &.Plast {
            background-image: url(../images/svg/facets/Emballasjetype-Plast.svg);
        }

        &.Øvrig {
            background-image: url(../images/svg/facets/Emballasjetype-Øvrig.svg);
        }

        &.Pouch {
            background-image: url(../images/svg/facets/Emballasjetype-Pouch.svg);
        }
    }

    &.icon-pie-1 {
        background-image: url(../images/svg/facets/icon-pie-1.svg);
    }

    &.icon-pie-2 {
        background-image: url(../images/svg/facets/icon-pie-2.svg);
    }

    &.icon-pie-3 {
        background-image: url(../images/svg/facets/icon-pie-3.svg);
    }

    &.icon-pie-4 {
        background-image: url(../images/svg/facets/icon-pie-4.svg);
    }

    &.icon-pie-5 {
        background-image: url(../images/svg/facets/icon-pie-5.svg);
    }

    &.icon-pie-6 {
        background-image: url(../images/svg/facets/icon-pie-6.svg);
    }

    &.icon-pie-7 {
        background-image: url(../images/svg/facets/icon-pie-7.svg);
    }

    &.icon-pie-8 {
        background-image: url(../images/svg/facets/icon-pie-8.svg);
    }

    &.icon-pie-9 {
        background-image: url(../images/svg/facets/icon-pie-9.svg);
    }

    &.icon-pie-10 {
        background-image: url(../images/svg/facets/icon-pie-10.svg);
    }

    &.icon-pie-11 {
        background-image: url(../images/svg/facets/icon-pie-11.svg);
    }

    &.icon-pie-12 {
        background-image: url(../images/svg/facets/icon-pie-12.svg);
    }

    &.icon-raastoff {
        background-image: url(../images/svg/facets/icon-raastoff.svg);
    }

    &.style {
        background-image: url(../images/svg/facets/style.svg);
    }
}

/**********************************/

details {
    &>summary>.icon-details {
        font-size: 24px;
        background-image: url(../images/svg/icon-plus-with-bg.svg);
        rotate: 0deg;
        transition: rotate .5s;
    }

    &[open]>summary>.icon-details {
        rotate: 45deg;

    }
}

/**********************************/

@property --rating {
    syntax: '<number>';
    initial-value: 0;
    inherits: true;
}

.rating-star--not-active,
.rating-star--active-1,
.rating-star--active-2,
.rating-star--active-3,
.rating-star--active-4,
.rating-star--active-5 {
    --rating: 0;
    transition: --rating 1s ease-out;
    background-image:
        conic-gradient(from -36deg, #66797C calc(var(--rating) * 72deg), transparent calc(var(--rating) * 72deg)),
        url('../images/star-inactive.svg') !important;
    print-color-adjust: exact;
    mask-image: url('../images/star-active.svg');
}

.rating-star--active-1 {
    --rating: 1;
}

.rating-star--active-2 {
    --rating: 2;
}

.rating-star--active-3 {
    --rating: 3;
}

.rating-star--active-4 {
    --rating: 4;
}

.rating-star--active-5 {
    --rating: 5;
}