@page {
    size: A4;
    margin: 0;
}

@media print {

    header,
    .related-articles,
    footer,
    .helpButton,
    .no-print {
        display: none !important;
    }

    .label {
        border: none;
    }

    a[href]:after {
        content: none;
    }
}