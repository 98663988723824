footer {
    background-color: var(--color-swamp);

    /* linker øverst: om vmp, presse, kundeservice osv */
    .section-footer-links {
        background-color: var(--color-white);

        .section__inner-container {
            padding: var(--section-padding);

            @media (--to-large) {
                --section-padding: 0;
            }
        }

        .footer-links {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            @media (--from-large) {
                flex-direction: row;
            }

            .navigation-toggler-container {
                @media (--to-large) {
                    &:not(:first-child) {
                        border-top: 1px solid var(--color-grey-02);
                    }
                }
            }

            .navigation-toggler-btn {
                color: var(--color-primary);
                background-color: transparent;
                font-size: 14px;
                font-weight: bold;
                padding: 0;
                padding-right: calc(16px + env(safe-area-inset-right));
                padding-left: calc(16px + env(safe-area-inset-left));
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0;
                width: 100%;
                --icon-size: 10px;

                @media (--from-large) {
                    display: none;
                }
            }

            .navigation-toggler-title {
                color: var(--color-primary);
                display: none;

                @media (--to-large) {
                    display: none;
                }

                @media (--from-large) {
                    display: block;
                    font-size: 16px;
                    margin-bottom: 24px;

                    &:after {
                        display: none;
                    }
                }
            }

            @media (--to-large) {
                button:not([aria-expanded=true])+.navigation-content-footer {
                    display: none;
                }
            }

            .navigation-content-footer {
                margin: 4px 0 8px;
                padding: 0;
                list-style: none;

                li {
                    @media (--from-large) {
                        margin-bottom: 8px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    a {
                        line-height: 140%;
                        color: var(--color-oxford);
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        border-bottom: none;

                        @media (--to-large) {
                            background-color: var(--color-grey-01-5);
                            border-radius: var(--border-radius);
                            padding: 0 16px;
                            height: 48px;
                            margin-top: 0;
                            margin-bottom: 4px;
                            margin-right: calc(16px + env(safe-area-inset-right));
                            margin-left: calc(16px + env(safe-area-inset-left));
                            font-size: 14px;
                        }

                        @media (--from-large) {
                            font-size: 16px;
                        }

                        &:focus,
                        &:active,
                        &:hover {
                            text-decoration: underline;
                        }

                        .icon {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    /* kontakt oss */
    .section-footer-contact {
        background-color: var(--color-swamp);

        @media (--to-medium) {
            .section__inner-container {
                --section-padding: 16px;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .footer-contact {
            padding: var(--section-padding) 0;

            .footer__headline {
                color: var(--color-white);
                font-size: 20px;
                line-height: 100%;
                margin-bottom: 32px;

                @media (--to-large) {
                    text-align: center;
                    margin-bottom: 24px;
                }
            }

            .footer-items {
                display: grid;
                row-gap: var(--section-padding);
                align-items: start;
                grid-template-columns: minmax(0, 1fr);

                @media (--desktop) {
                    grid-template-columns: auto 300px max-content;
                    justify-content: space-between;
                    column-gap: 24px;
                }
            }

            .footer-opening-hours {
                color: var(--color-iron);
                margin-bottom: 0;
                line-height: 1.5;
                display: grid;
                grid-template-columns: 1fr max-content;

                @media (--desktop) {
                    column-gap: 24px;
                }

                b {
                    grid-column: span 2;
                }
            }

            /* kontaktknapper for tlf og callback */
            #ContactUsNavNode {
                display: grid;
                grid-row-gap: 8px;
                --icon-size: 22px;
            }

            /* epost og facebook-linker */
            #footer-contact-links {
                display: grid;
                grid-gap: 16px;
                text-align: center;

                @media (--desktop) {
                    text-align: unset;
                }

                .VmpOnFacebookLink a {
                    background-image: url("../images/facebook.svg");
                }

                .VmpOnLinkedInLink a {
                    background-image: url("../images/linkedIn.svg");
                }
            }
        }

        /* adresse og link til engelsk-info */
        .extended-footer {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            align-items: stretch;
            row-gap: var(--section-padding);
            padding: var(--section-padding) 0;
            border-top: 1px solid var(--color-oxford);

            @media (--desktop) {
                grid-template-columns: 1fr auto;
                column-gap: 24px;
            }

            .extended-footer-title {
                background-image: url("../images/crest.svg");
                background-repeat: no-repeat;
                background-position: 0 50%;
                background-size: 32px 32px;
                padding-left: 56px;

                p {
                    color: var(--color-iron);
                    font-size: 14px;
                    line-height: 150%;
                    margin: 0;
                }
            }
        }
    }
}

.footer-icon-link,
.footer-button {
    border-bottom: none;
    color: var(--color-iron);
    font-size: 16px;
    line-height: 140%;
    background-position: 0 50%;
    background-repeat: no-repeat;
    padding-left: 32px;
    display: inline-block;

    &:focus,
    &:active,
    &:hover {
        text-decoration: underline;
    }
}

.footer-button {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: var(--border-radius);
    background-color: var(--color-oxford);
    text-align: center;
    padding: 16px 24px;
}