.container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    gap: 16px;

    &.centered {
        justify-content: center;

        @media (--from-xsmall) {
            margin-top: 24px;
        }
    }

    a,
    button {
        min-height: 48px;
        padding: 16px;
    }

    :global .btn-secondary {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: normal;

    }
}