.message-box,
.error-message,
.info-message {
    padding: 16px;
    max-width: var(--medium-content-width);
    margin: 0 auto;
    border-radius: 4px;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    text-align: left;
    align-items: center;

    @media (--to-medium) {
        margin: 16px;
    }

    .icon {
        font-size: 20px;
    }

    ul {
        padding-left: 20px;
        list-style: disc;
        margin-bottom: 0;
        margin-left: 10px;
    }

    .message-text {
        padding-left: 16px;
        overflow-wrap: break-word;
        overflow-x: auto;
    }
}

.error-message {
    background-color: var(--state-danger-bg);
    color: var(--state-danger-text);

    &.margin-bottom {
        @media (--to-small) {
            margin: 0 0 16px 0;
        }
    }
}

.info-message {
    background-color: var(--color-cream-brulee);
    border: none;
    max-width: none;

    &.margin-bottom {
        margin-bottom: 16px;
    }
}