.product-item {
    padding: 16px;
    position: relative;
    background-color: var(--color-white);


    @media (--to-medium) {
        padding-right: 48px;
        /* make space for .product-tools */
    }

    .product__image-container {
        border-bottom: none;
        display: block;
        margin: 0 auto 16px;
        max-width: none;
        min-height: 200px;
        width: auto;

        @media (--to-small) {
            width: 60px;
            min-height: unset;
            margin: 0;
        }

        img {
            height: 200px;
            max-height: 200px;
            width: 100%;
            object-fit: contain;

            @media (--to-small) {
                width: 60px;
                height: 175px;
            }
        }

        .expired-img {
            width: 90%;
            left: 5%;
        }
    }

    .link-text {

        &:focus,
        &:active,
        &:hover {
            border-bottom: 1px solid var(--color-oxford);
        }
    }

    .info-container {
        min-height: 125px;
        margin-bottom: 10px;

        @media (--to-small) {
            min-height: unset;
        }
    }

    .image-and-info-container {
        @media (--to-small) {
            display: flex;
            column-gap: 8px;
        }
    }

    .product-stock-status {
        margin-top: 16px;
    }

    .product-item__stock-status {
        color: var(--color-secondary);
        margin: 8px 0 21px;
    }

    .product__category-name {
        margin: 0 0 4px;
    }

    .product__name {
        font-size: 18px;
        display: inline;

        a {
            color: var(--color-primary);
        }
    }

    .product__price {
        font-size: 28px;
    }

    .product__code {
        margin: 8px 0;
    }

    .product-tools {
        top: 8px;
        right: 8px;
    }

    .product-item-review {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid var(--color-grey-02);
    }

    .entry-creation-date {
        margin-top: 16px;
        color: var(--color-nevada);
        font-size: 12px;
    }
}

.product-item__cost_per_unit {
    display: block;
    font-size: 14px;
}

.product-item__image--expired {
    img {
        opacity: 0.5;
    }
}

.image-and-info-container {
    @media (--to-small) {
        display: flex;
        column-gap: 8px;
    }

    .product-item__image {
        @media (--to-small) {
            width: 90px;
            min-height: unset;
            margin: 32px auto 0;
        }

        img {
            @media (--to-small) {
                width: 90px;
                height: 175px;
                object-fit: contain;
            }
        }
    }

    .price-panel {
        margin: 16px 0;
        width: 100%;

        .amount {
            color: var(--color-nevada);
            font-weight: 600;
            text-transform: lowercase;
            margin-left: 8px;
        }
    }

    .product-price-expired {
        padding: 16px 0;
    }
}

/* TODO: move this into product-review.css*/
.product-item__rating-stars {
    color: var(--color-oxford);
    --star-empty: url('../images/star-inactive.svg');
    --star-filled: url('../images/star-active.svg');

    >input {
        appearance: none;
        width: var(--icon-size, 24px);
        height: var(--icon-size, 24px);
        box-sizing: content-box;
        padding-inline: calc(var(--icon-size, 24px) / 3);
        /* stars are empty by default*/
        background: var(--star-empty) no-repeat center center;

        &:first-child {
            padding-left: 0;
            background-position: left;
        }

        &:last-child {
            padding-right: 0;
            background-position: right;
        }

        /* all stars up to and including the checked one should be filled */
        &:checked,
        &:has(~input:checked) {
            background-image: var(--star-filled);
        }

        /* Don't care about hover when the rating is disabled */
        &:not(:disabled) {
            cursor: pointer;

            /* All stars up and including to the hovered one should be filled */
            &:hover,
            &:has(~input:hover) {
                background-image: var(--star-filled);
            }

            /* All stars after the hovered one should be empty*/
            &:hover~input {
                background-image: var(--star-empty);
            }

            &:checked:hover,
            &:has(~input:checked:hover) {
                background-image: var(--star-empty);
            }
        }
    }
}

.product-item-quantity-and-sum+.product-item__entry-status {
    margin-top: 8px;
}

.product-item__entry-status {
    margin-bottom: 0;
    padding-top: 8px;
    border-top: 1px solid var(--color-iron);
    font-size: 14px;
}