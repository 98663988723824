button {
    border: none;
}

.btn,
.btn-primary,
.btn-secondary,
.btn-icon {
    --padding-v: 16px;
    --padding-h: 24px;
    border-radius: 4px;
    padding: var(--padding-v) var(--padding-h);
    font-size: var(--font-size-smaller);
    line-height: 100%;
    white-space: normal;
    display: inline-block;

    &:active {
        box-shadow: none;
    }

    a&:hover,
    a&:active {
        border-bottom-width: 1px;
    }
}

.btn-primary {
    background-color: var(--color-swamp);
    border: 1px solid var(--color-swamp);
    color: var(--color-white);
    font-weight: bold;
    min-width: 96px;

    &:hover {
        background-color: var(--color-oxford);
        border-color: var(--color-oxford);
        color: var(--color-white);
    }

    &:focus {
        color: var(--color-white);
    }

    &:disabled {
        background-color: var(--color-oslo-grey);
        border-color: var(--color-oslo-grey);
        color: var(--color-iron);
    }
}

.btn-secondary {
    background-color: var(--color-white);
    border: 1px solid var(--color-iron);
    color: var(--color-swamp);
    font-weight: normal;
    min-width: 96px;

    &:hover {
        background-color: var(--color-haze);
        border-color: var(--color-iron);
        color: var(--color-swamp);
    }

    &:focus {
        color: var(--color-swamp);
    }

    &:disabled {
        opacity: 0.5;
    }
}

/*
buttons with own focus and hover visualization. We state these explicity as the default focus and hover
states are underlines due to accessibility concerns.
*/

.btn--white {
    color: var(--color-secondary);
    background-color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: var(--border-radius);
    box-shadow: none;
    text-transform: none;
    padding: 24px;
    font-size: 16px;
    font-weight: normal;

    &:hover,
    &:focus {
        background-color: var(--color-haze);
    }

    .main {
        color: var(--color-primary);
        font-weight: bold;
    }
}

.btn--transparent,
.btn-icon,
.btn-text-and-icon {
    color: var(--color-swamp);
    padding: 0;
    background-color: transparent;
    border: none;

    &:hover {
        text-decoration: none;
        background-color: transparent;
        color: inherit;
        border: none;

        .btn__text {
            text-decoration: underline;
        }
    }
}

.btn-text-and-icon {
    /* TODO: this is only used two places, on the cart page */
    color: var(--color-secondary);
    font-size: 14px;
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: underline;
    }

    svg,
    .icon {
        margin-left: 8px;
    }
}

button.btn-underline-link {
    height: 24px;
    line-height: 24px;
    padding: 0;
    color: var(--color-blue-stone);
    background: transparent;
    border: 0;
    border-bottom: 1px solid var(--color-blue-stone);
}

.btn-tertiary {
    background-color: var(--state-info-bg);
    color: var(--state-info-text);
    border: var(--state-info-border);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    padding: 16px;
    margin-top: 20px;
    text-align: left;
    line-height: 120%;

    svg {
        flex: 1 0 auto;
        margin-right: 12px;
    }
}

.btn-icon {
    padding: 4px;
    font-size: 20px;
    border-radius: 4px;
    position: relative;
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: var(--color-narvik);
    }

    @media (--to-large) {
        font-size: 26px;
    }
}

.btn-spinner,
.btn-spinner:disabled {
    background-size: 1em;
    color: transparent;

    &:hover,
    &:focus,
    &:active {
        color: transparent;
    }
}

.btn-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    column-gap: 8px;
    flex-flow: wrap;

    .btn-group-btn {
        background-color: var(--color-white);
        border: 2px solid var(--color-grey-02);
        color: var(--color-oxford);
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        box-shadow: none;
        margin-top: 4px;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        height: 48px;
        width: 78px;

        &:active {
            background-color: var(--color-apple-green);
            border-color: var(--color-oslo-grey);
            box-shadow: none;
        }
    }
}

.option-list-item-content {
    background-color: var(--color-white);
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-size: 16px;
    padding: 24px;
    color: var(--color-swamp);
    margin-bottom: 0;
    width: 100%;
    border: 0;
    border-radius: 4px;
    align-items: center;

    p {
        margin: 0;
    }
}

label.option-list-item-content {
    font-weight: normal;
    line-height: 140%;
    color: var(--color-oxford);
}

button.option-list-item-content {
    &:hover {
        text-decoration: underline;
    }
}