body {
    line-height: 100%;
    font-family: 'Roboto', sans-serif;
    color: var(--color-swamp);
    font-size: 14px;

    @media (--from-large) {
        font-size: 16px;
    }

    background-color: var(--site-background);

    >header {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 10;

        /*
    This is a trick since the header-category-bar slides up, so that the space it wasn't isn't clickable.
    The rule below makes the two (or more) elements inside the header clickable
    */
        pointer-events: none;

        &>* {
            pointer-events: all;
        }

        a {
            border-bottom: none;
        }

        .section__logo-and-nav-toggler {
            background-color: var(--color-swamp);
            z-index: 1;
            position: relative;
        }
    }

    >main {
        position: relative;
        min-height: 100vh;
    }
}