a,
button.link {
  cursor: pointer;
  border-bottom: 1px solid var(--color-blue-stone);
  text-decoration: none;
  color: var(--color-blue-stone);

  &.disabled {
    color: var(--btn-link-disabled-color);
    cursor: not-allowed;

    &:focus,
    &:active,
    &:hover {
      text-decoration: none;
    }
  }

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:active,
  &:hover {
    border-bottom-width: 2px;
  }
}

button.link {
  /* workaround for border changing size */
  --button-border-bottom: 1px;
  padding: 0 0 var(--button-border-bottom) 0;
  background: inherit;
  appearance: none;
  display: inline;

  &:active,
  &:hover {
    --button-border-bottom: 0px;
  }
}

a.button {
  border-bottom: none;

  &:focus,
  &:active,
  &:hover {
    border-bottom: none;
  }
}