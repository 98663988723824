/* Chat bubble */
.embeddedServiceHelpButton {
    .helpButton {
        .uiButton {
            padding: 0 16px !important;
            background-color: var(--color-swamp) !important;
            font-family: 'Roboto', sans-serif !important;
            max-width: 20em !important;

            &:focus {
                outline: 1px solid #002025 !important;
            }

            .helpButtonLabel .message {
                font-size: 16px !important;
            }

            .embeddedServiceIcon {
                display: inline-block !important;
            }

            @media (max-width: --screen-sm) {
                min-width: 5em !important;
                max-width: 5em !important;

                .embeddedServiceIcon {
                    &::before {
                        font-size: 2.4em !important;
                    }
                }

                .helpButtonLabel .message {
                    display: none !important;
                }
            }
        }

        @media (max-width: --screen-sm) {
            width: 77px !important;
        }
    }
}

/* Form button */
.sidebarBody .embeddedServiceSidebarForm .embeddedServiceSidebarButton {
    font-size: 16px;
    background-color: var(--color-swamp) !important;
    font-weight: bold;
}

/* Placement of chat bubble */
.embeddedServiceSidebar.modalContainer {
    font-family: 'Roboto', sans-serif !important;

    .embeddedServiceSidebarMinimizedDefaultUI.sidebarHeader {
        bottom: 0;
        right: 12px;
        max-width: 15em;

    }
}

/* Collapsed chat bubble */
.embeddedServiceSidebarMinimizedDefaultUI {
    .content .messageContent {
        .minimizedText .message {
            font-size: 16px;
        }

        @media (max-width: --screen-sm) {
            .embeddedServiceIcon.x-small {
                margin-right: 0;

                svg {
                    width: 1.7em;
                    height: 1.7em;
                }
            }

            .minimizedText .message {
                display: none;
            }
        }
    }

    @media (max-width: --screen-sm) {
        min-width: 4em !important;
        max-width: 4em !important;
    }
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
    background-color: var(--color-apple-green) !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
    color: var(--color-swamp) !important;
}

/* Form labels */
.embeddedServiceSidebarFormField .uiInput .uiLabel-left {
    font-size: 12px !important;
    text-transform: uppercase;
    padding-left: 5px;
    letter-spacing: 0.5px;
    color: var(--color-oxford) !important;
}

/* Chat send button */
.embeddedServiceLiveAgentStateChatInputFooter .chatActionButton.Send {
    padding: 13px 0px 0 8px !important;
}

.embeddedServiceLiveAgentStateChatAction.uiButton:disabled svg,
.embeddedServiceLiveAgentStateChatAction.uiButton:disabled:hover svg,
.embeddedServiceLiveAgentStateChatAction.uiButton:disabled:focus svg {
    fill: var(--color-oxford) !important;
}

/* Chat bubble size on mobile */
.embeddedServiceSidebarMinimizedDefaultUI.sidebarHeader {
    @media (max-width: --screen-sm) {
        bottom: 12px !important;
        height: 46px !important;
        width: 77px !important;
        border-radius: 23px !important;
    }
}

/* Display chat icon on mobile */
.embeddedServiceSidebarMinimizedDefaultUI:not(.helpButton) .minimizedText {
    @media (max-width: --screen-sm) {
        content: url('../images/Chat-salesforce.svg') !important;
        width: 29px !important;
    }
}

/* Minimize and close button */
.closeButton {
    width: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .slds-icon-utility-close svg {
        width: 20px;
    }

    &:hover[embeddedService-chatHeader_chatHeader]:before {
        right: 18px !important;
    }
}

.minimizeButton {
    display: flex;
    justify-content: center;
    align-items: center;

    .slds-icon-utility-minimize-window svg {
        width: 20px;
    }

    &:hover[embeddedService-chatHeader_chatHeader]:before {
        right: 54px !important;
    }
}