/* TODO: this should be a css module */

.my-list {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		max-height: 270px;
		overflow-y: auto;
	}

	.new-list h2 {
		font-size: var(--font-size-smaller);
		margin: 0 0 16px;
		text-align: left;
	}

	.new-list {
		margin-top: 16px;
		display: flex;
		flex-direction: column;

		.error-message {
			margin: 0 0 16px 0;
		}

		form {
			display: grid;
			grid-template-columns: minmax(200px, 500px) auto;
			grid-gap: 8px;
		}
	}

	.new-list-btn {
		align-self: end;
		display: flex;
		align-items: center;
		grid-gap: 8px;
	}
}

.my-list-item {
	background: var(--color-narvik);
	border-radius: 4px;

	&+.my-list-item {
		margin-top: 8px;
	}

	.checkbox-wrapper {
		padding: 16px;
	}
}

.my-list-item-favorite {
	background: var(--color-old-lace);

	label {
		--icon-size: 20px;


		input[type="checkbox"] {
			width: 24px;
			height: 22px;
			border: none;
			background: transparent url('../images/svg/icon-favorite.svg') center/24px;
			position: relative;
			border-radius: 0;

			&:hover {
				border: none;
				filter: drop-shadow(0 0 1px var(--color-oslo-grey));
			}

			&:checked {
				background: none;

				&::before {
					content: '';
					display: block;
					position: absolute;
					clip-path: path('M11.7512 21.9466C11.8295 21.9822 11.9143 22.0004 12 22C12.0857 22.0004 12.1705 21.9822 12.2488 21.9466C12.3271 21.9109 12.3971 21.8587 12.4542 21.7933L22.2084 11.2386C22.9852 10.3878 23.5357 9.34709 23.8075 8.21618C24.0792 7.08526 24.063 5.90188 23.7605 4.77919C23.4876 3.67482 22.9304 2.66559 22.147 1.85668C21.3635 1.04777 20.3825 0.468795 19.3061 0.180089C18.2832 -0.0822747 17.2102 -0.0575747 16.1998 0.251606C15.1895 0.560787 14.2792 1.14302 13.5647 1.93705L12 3.62941L10.4353 1.93705C9.72082 1.14302 8.8105 0.560787 7.80015 0.251606C6.78981 -0.0575747 5.71679 -0.0822747 4.69388 0.180089C3.61753 0.468795 2.63646 1.04777 1.85301 1.85668C1.06956 2.66559 0.512371 3.67482 0.239495 4.77919C-0.0630408 5.90188 -0.0792252 7.08526 0.192514 8.21618C0.464253 9.34709 1.01481 10.3878 1.79155 11.2386L11.5457 21.7933C11.6029 21.8587 11.6729 21.9109 11.7512 21.9466Z');
					top: 0px;
					left: 0px;
					width: 24px;
					height: 22px;
					background: var(--color-oxford);
				}
			}
		}
	}
}

label {
	&:hover {
		cursor: pointer;
	}
}