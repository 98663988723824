.site__logo-and-nav-toggler {
    height: var(--header-navbar-height);
    display: grid;
    grid-template:
        'logo search menu' auto /
        min-content 1fr min-content;
    align-items: center;
    justify-items: center;
    padding: 0 16px;
    width: 100%;
    position: relative;

    @media (--from-medium) {
        padding: 0;
    }

    @media (--from-xlarge) {
        grid-template:
            'logo search nav menu' auto /
            min-content minmax(320px, auto) minmax(320px, auto) min-content;
    }

    /* vmp-logo */
    .site__go-home {
        grid-area: logo;
        display: block;
        width: 36px;
        height: 36px;
        margin: 3px 0 0 0;

        &:before {
            display: block;
            text-align: left;
            margin: 0;
            content: '';
            width: 36px;
            height: 36px;
            background: url('../images/crest.svg') 0 0 no-repeat;
            background-size: 36px 36px;
        }

        @media (--from-medium) {
            width: 212px;
            height: 16px;
            margin: 0;

            &:before {
                width: 212px;
                height: 16px;
                background: url('../images/header/header-logo.svg') 0 0 no-repeat;
                background-size: 212px 16px;
            }
        }

        @media (--from-large) {
            width: 261px;
            height: 20px;

            &:before {
                width: 261px;
                height: 20px;
                background-size: auto;
            }
        }
    }

    @media (--to-small) {
        .site__search-container {
            grid-area: 1/1/-1/-1;
            /* Cover the entire width */
            position: relative;
            z-index: 1;
        }
    }

    /* butikker, les og lær osv for desktop */
    .primary-navigation-desktop {
        grid-area: nav;
        justify-self: start;
        display: none;

        @media (--from-xlarge) {
            display: unset;
        }

        .primary-navigation-items-desktop {
            .primary-navigation__top-nav-items {
                display: flex;
                gap: 32px;
                margin: 0;
                padding: 0;
                list-style: none;

                /* butikker, les og lær osv */
                >li {
                    white-space: nowrap;
                    text-align: center;
                    position: relative;

                    &>a {
                        color: var(--color-iron);
                        font-weight: bold;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        --icon-size: 10px;

                        &:hover {
                            color: var(--color-white);

                            .icon {
                                background-color: currentColor;
                            }
                        }
                    }


                    .primary-navigation__top-nav-item-children {
                        left: 0;
                        position: absolute;
                        width: 200px;
                        z-index: 20;
                        top: 46px;

                        a:hover {
                            background-color: var(--color-oxford);
                        }

                    }
                }
            }
        }
    }

    .secondary-navigation {
        grid-area: menu;
    }


    .hamburger-menu {
        @media (--from-xlarge) {
            display: none;
        }
    }
}

.header-mobile-menu {
    position: absolute;
    z-index: 20;
    background-color: var(--color-swamp);
    padding: 16px;
    overflow-y: scroll;
    max-height: calc(100vh - 100%);

    top: 100%;
    left: 0;
    right: calc(-1 * var(--section-padding));
    width: 100%;

    @media (--to-xxsmall) {
        height: calc(100vh - 100%);
    }

    @media (--from-medium) {
        left: unset;
        width: 375px
    }

    display: none;

    [aria-expanded=true]+& {
        display: block;
    }

    /* butikker, les og lær osv for mindre enn desktop */
    .primary-navigation__top-nav-items {

        margin: 0;
        padding: 0;
        list-style: none;

        >li {
            background-color: var(--color-oxford);
            margin-bottom: 4px;
            border-radius: var(--border-radius);

            &:last-of-type {
                margin-bottom: 0;
            }

            &.active,
            &:hover {
                background-color: var(--color-nevada);
            }

            &>a {
                color: var(--color-white);
                padding: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: bold;
                --icon-size: 10px;

                .icon {
                    background-color: white;
                }
            }

            .primary-navigation__top-nav-item-children {
                padding: 12px 0 8px 0;
                gap: 4px;

                a {
                    border: 1px solid var(--color-oxford);
                    border-radius: var(--border-radius);
                }
            }
        }
    }

    .primary-navigation__logout {
        background-color: var(--color-swamp);
        border: 1px solid var(--color-oxford);
        margin-top: 24px;
        display: block;
        border-radius: var(--border-radius);

        a {
            text-align: center;
            color: var(--color-white);
            padding: 16px;
            width: 100%;
            display: block;
            position: relative;
            font-weight: bold;

            &.btn-spinner {
                color: transparent;
                pointer-events: none;
                cursor: default;
            }

            &:hover {
                background-color: var(--color-oxford);
            }
        }
    }
}

.secondary-navigation {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (--from-medium) {
        gap: 24px;
    }

    .secondary-navigation__link-name {
        font-size: 14px;
        display: none;
        overflow: hidden;
        max-width: 75px;
        white-space: nowrap;
        margin: 0;

        @media (--from-large) {
            display: block;
        }

        &:after {
            border-bottom: 2px solid var(--color-mint-green);
            transform: scaleX(0);
            transition: transform 150ms ease-in-out;
            display: block;
            content: '';
        }
    }

    /* min side og mine lister i mobilmeny */
    .secondary-navigation-items-mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;

        .secondary-navigation__link {
            padding: 24px;
            width: unset;
            background-color: var(--color-oxford);
            border-radius: var(--border-radius);
            display: flex;
        }

        .secondary-navigation__link-name {
            display: block;

            &:after {
                content: none;
            }
        }
    }
}

/* Menypunktene Min side, Mine lister og Handlekurv */

.secondary-navigation__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding: 0;
    color: var(--color-iron);
    line-height: 1;
    --icon-size: 28px;

    @media (--from-large) {
        width: 70px;
    }

    &.min-side__link,
    &.favourites__link {
        @media (--to-medium) {
            display: none;
        }
    }

    &:hover,
    &:active,
    &:focus,
    /* active on login and my-account pages */
    :is(.page-login, .page-account) &.min-side__link,

    /* active when in the wishlist pages */
    .page-wishlistPage &.favourites__link,

    /* active when on the cart page */
    .page-cartPage &.cart__link {
        color: var(--color-mint-green);

        .secondary-navigation__link-name {
            &:after {
                transform: scaleX(1);
            }
        }
    }

    .icon {
        background-color: currentColor;
    }
}

.cart__icon {
    position: relative;
    display: flex;

    .cart__count {
        background-color: var(--color-berry-red);
        color: white;
        font-size: 13px;
        font-weight: bold;
        border-radius: 11px;
        line-height: 100%;
        min-width: 22px;
        height: 22px;
        position: absolute;
        padding: 5px;
        left: auto;
        right: -12px;
        top: -5px;
        text-align: center;

        &[data-count='0'] {
            display: none;
        }
    }
}

@property --header-height {
    syntax: '<length>';
    initial-value: 0;
    inherits: true;
}

body {
    --header-navbar-height: 64px;
    --header-category-bar-height: 56px;
    --header-height: calc(var(--header-navbar-height) + var(--header-category-bar-height));
    --header-max-height: calc(var(--header-navbar-height) + var(--header-category-bar-height));
    transition: --header-height .2s ease-out;

    @media (--from-large) {
        --header-navbar-height: 76px;
    }

    &:has(.header-category-bar.scroll-hidden:not(:focus-within)) {
        --header-height: var(--header-navbar-height);
    }
}

.primary-navigation__top-nav-item-children {
    background-color: var(--color-swamp);
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    a {
        display: block;
        text-align: left;
        padding: 16px;
        font-size: 16px;
        font-weight: bold;
        color: var(--color-iron);
        line-height: 100%;
        background-color: var(--color-swamp);

        &:hover {
            color: var(--color-white);
        }
    }

    [aria-expanded]:not([aria-expanded=true])+& {
        display: none;
    }
}