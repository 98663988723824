.informative-page {
    header {
        .site__logo-and-nav-toggler {
            grid-template: 'logo';
        }

        .site__search-container,
        .header-category-bar,
        .primary-navigation-desktop,
        .secondary-navigation {
            display: none;
        }
    }

    footer {

        .footer_column-1,
        .footer_column-2,
        .section.section--divider,
        .section-footer-links {
            display: none;
        }
    }
}

.informative-page__content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 16px;
    flex-direction: column;
    gap: 48px;

    @media (--desktop) {
        flex-direction: row;
    }

    img {
        width: 200px;
        max-height: 200px;
        object-fit: contain;
    }

    .info-message {
        @media (--to-medium) {
            /* override info-message getting margin: 16px; */
            margin: 0 0 16px;
        }
    }
}

.informative-page__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p,
    li {
        margin-bottom: 16px;
        font-size: 16px;
    }
}