.dialog {
    width: 90vw;
    overflow-y: auto;
    border-radius: 8px;
    border: none;
    padding: 0;

    &::backdrop {
        background-color: rgb(0 0 0 / 0.8);
    }

    &.small {
        max-width: 100%;

        @media (--from-xsmall) {
            max-width: 600px;
        }
    }

    &.medium {
        max-width: 100%;

        @media (--from-xsmall) {
            max-width: 800px;
        }
    }

    &.large {
        width: 100%;

        @media (--from-xsmall) {
            width: 90%;
        }

        @media (--from-large) {
            width: 930px;
        }

        @media (--from-xlarge) {
            width: 1100px;
        }
    }
}

.dialog-content {
    position: relative;
    background-color: var(--color-white);
    background-clip: padding-box;
    outline: 0;
    border-radius: 8px;
    overflow-y: auto;
    padding: 16px;

    @media (--from-medium) {
        padding: 32px;
    }

    @media (--from-xlarge) {
        padding: 40px;
    }

    @media (--to-small) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        max-height: fit-content;
    }

    &.white {
        background-color: white;
    }

    &.no-padding {
        padding: 0;
    }

    h1,
    h2,
    h3 {
        text-align: center;
        color: var(--color-swamp);
        margin: 0 auto 16px;
    }

    h1 {
        @media (--desktop) {
            font-size: 26px;
        }
    }

    p {
        margin: 0 auto 16px;
        text-align: center;
        max-width: 345px;

        @media (--from-xsmall) {
            margin: 24px auto;
        }

        @media (--from-large) {
            margin: 32px auto;
        }
    }
}

.dialog-close {
    position: absolute;
    right: 16px;
    top: 16px;
    background: transparent;
    font-size: 24px;
    padding: 0;
    z-index: 1;
}