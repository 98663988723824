.empty-state {
    min-height: 300px;
    margin: 0 auto;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .icon {
        font-size: 32px;
    }

    .empty-state-text {
        color: var(--color-primary);
        margin-top: 24px;
        font-size: 16px;
        font-weight: bold;
    }
}