.product-list,
.article-grid {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;

    @media (--from-medium) {
        grid-gap: 8px;
    }

    --grid-columns: 1;
    grid-template-columns: repeat(var(--grid-columns), 1fr);

    &.row-max-3 {
        @media (--from-medium) {
            --grid-columns: 2;
        }

        @media (--from-xlarge) {
            --grid-columns: 3;
        }
    }

    &.row-max-4 {
        @media (--from-medium) {
            --grid-columns: 2;
        }

        @media (--from-large) {
            --grid-columns: 3;
        }

        @media (--from-xlarge) {
            --grid-columns: 4;
        }
    }

    .product-item {
        @media (--to-medium) {
            border-bottom: 1px solid var(--color-grey-02);
        }
    }

    li.product-item:last-child {
        border-bottom: none;
    }
}

.product-list-horizontal {
    display: grid;
    grid-gap: 0;
    grid-row-gap: 8px;
    grid-template-columns: auto;
    width: 100%;

    .product-item {
        text-align: left;
        padding: 24px;
        display: grid;
        grid: 'image info' / 60px 1fr;
        grid-column-gap: 16px;
        align-items: center;
        border-bottom: 1px solid var(--color-grey-02);

        @media (--to-small) {
            padding: 16px;
            grid-column-gap: 8px;
        }

        .product-item__image {
            grid-area: image;
            margin: 0;
            min-height: auto;
            text-align: center;

            @media (--to-small) {
                width: 60px;
                min-height: unset;
                margin: 0;
            }

            img {
                width: 60px;
                object-fit: contain;
                height: 100px;
            }
        }

        .info-container {
            grid-area: info;
            font-size: 14px;
            margin: 0;
            min-height: auto;
            height: 100%;
            display: flex;
            flex-flow: column;
        }

        .product__name {
            margin-bottom: 16px;
            display: block;
        }

        .product__price {
            font-size: 18px;
            font-weight: normal;
        }

        .product-item-quantity-and-sum {
            display: grid;
            grid-template:
                "quantitypicker totalsum" 40px
                "quantitypicker ." auto;
            align-items: flex-end;
            justify-content: space-between;
            padding-top: 16px;
        }

        .product-item__stock-status {
            color: var(--color-secondary);
            margin: 16px 0 0;
        }

        .product-item-info-box {
            margin-top: 8px;
        }

        .product-item-price-and-volume {
            display: flex;
        }

        .volume-and-cost_per_unit {
            font-weight: bold;
            margin-left: 8px;
        }

        .product-item__qty-submit {
            display: inline-block;
            width: auto;
            font-size: 14px;
        }

        .product-item__quantity {
            color: var(--color-secondary);
        }

        .total-sum {
            color: var(--color-primary);
            font-size: 18px;
            font-weight: bold;
            grid-area: totalsum;
        }
    }
}