.search-page {
    .info-message {
        margin-bottom: 16px;
    }

    .spinner {
        margin-top: 60px;
        background-color: transparent;

        @media (--from-medium) {
            margin-top: 120px;
        }
    }

    .search-wrapper {
        display: grid;
        grid-template-columns: 1fr;

        @media (--from-large) {

            &:not(:has(>:only-child)) {
                grid-template-columns: 347px 1fr;
                column-gap: 8px;
            }
        }
    }

    .search-controls {
        margin-bottom: 24px;

        @media (--to-medium) {
            padding: 0 16px;
            margin-bottom: 16px;
        }

        .search-controls--filters {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            @media (--from-large) {
                display: none;
            }
        }

        .search-controls--options {
            display: flex;
            margin-top: 16px;
            justify-content: space-between;
        }

        .search-controls--result-types {
            display: flex;
            gap: 16px;

            a,
            span {
                color: var(--color-deep-sea-green);
                font-size: 14px;
            }

            span.selected {
                font-size: 14px;
                text-decoration: none;
                border-bottom: 2px solid transparent;
            }
        }
    }

    .search-page__no-results {
        display: block;
        padding: 15px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        max-width: 650px;

        h2 {
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 15px;
        }

        p {
            color: var(--color-oxford);
            line-height: 180%;
        }

        .search-page__spelling-suggestions {
            margin-top: 20px;
            font-weight: bold;
        }

        @media (--from-medium) {
            width: 80%;
            max-width: 800px;

            p {
                font-size: 16px;
                line-height: 26px;
            }

            h2 {
                font-size: 22px;
                line-height: 26px;
            }
        }

        @media (--from-large) {
            width: 100%;

            p {
                font-size: 18px;
                line-height: 24px;
            }

            h2 {
                font-size: 24px;
                line-height: 29px;
            }
        }
    }
}