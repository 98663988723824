.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  background-color: var(--color-apple-green);
  font-size: 14px;
  padding: 0 16px;

  button {
    all: unset;
    font-size: 20px;
    height: 20px;
    width: 20px;
  }

  @media (--from-medium) {
    display: none;
  }
}